import { API_NAMES, initApi } from "../../../utils/HttpApiHelper";

const postAccountResetPasswordData = (href, currentPassword, newPassword, state, setState) => {
  if (href && currentPassword && newPassword) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const url = `/Account/PasswordReset`;
    const payload = {
      href: href,
      currentPassword: currentPassword,
      newPassword: newPassword
    };

    return api?.executeApi ? api.executeApi(url, 'POST', payload) : null;
  }
};

const AccountExpiredPasswordData = {
  postAccountResetPasswordData
};

export default AccountExpiredPasswordData;