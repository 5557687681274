import { API_NAMES, initApi } from "../../../utils/HttpApiHelper";

const postAccountOneTimePasswordData = (href, oneTimePassword, state, setState) => {
  if (href && oneTimePassword) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const url = `/Account/OneTimePasswordCheck`;
    const payload = { href, otp: oneTimePassword };

    return api?.executeApi ? api.executeApi(url, 'POST', payload) : null;
  }
};

const AccountOneTimePasswordData = {
  postAccountOneTimePasswordData
};

export default AccountOneTimePasswordData;